import '../css/index.scss'
import {handleEvents} from "./events";
import {initDefaultSelect2} from "./select2";
import {disableConsoleLogs} from "./utils";


$(document).ready(function () {
    disableConsoleLogs()
    handleEvents()
    initDefaultSelect2()

})