export const commonConfig = {
    currencies: {
        'UAH': 'грн.',
        'USD': '$',
        'EUR': '€',
        'PLN': 'zł',
        'RON': 'lei',
        'BGN': 'лв.',
        'KZT': 'тг.',
    },
    countries: ['UA', 'PL', 'BG', 'RO', 'PT', 'KZ']
}

export const headerConfig = {
    navMenuTimeout: 0,
    langMenuTimeout: 0,
}

