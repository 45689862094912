const cssVariableCache = {};

// Функция для получения значения CSS переменной с кэшированием
export function getCssVariableValue(variableName) {
  // Проверяем, есть ли уже кэшированное значение
  if (cssVariableCache[variableName]) {
    return cssVariableCache[variableName];
  }

  // Если значения нет в кэше, получаем его и сохраняем в кэше
  const style = getComputedStyle(document.documentElement);
  const value = style.getPropertyValue(variableName).trim();

  // Сохраняем значение в кэше
  cssVariableCache[variableName] = value;

  return value;
}